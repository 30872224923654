<template>
  <div class="scoped_cover_div">
    <div class="search-text">
      <select v-model="filter.role" @change="fetchUsers('reset')" name="" id="" class="role">
        <option value="">All Role</option>
        <option value="is_admin">Hub Admin</option>
        <option value="normal_user">Normal User</option>
        <option value="is_super_admin">Super Admin</option>
      </select>
      <input type="text" class="filter-text" @change="fetchUsers('reset')" v-model="filter.text" placeholder="Filter Name or Email">
    </div>
    <div class="jobs_table sp2" id="user-table-management"style="border-top:unset;">
      <table class="table">
        <thead>
          <tr>
            <th>NAME</th>
            <th>EMAIL</th>
            <th>BRANCH</th>
            <th class="width_33">USER TYPE</th>
            <th class="width_22">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr  v-for="(user,i) in users" :key="i">
            <td>
              <div class="label_and_element_wrapper">
                <input type="text" placeholder="Enter" :value="user.name" :disabled="cancelEditable" />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <input type="text" placeholder="Enter" :value="user.email" :disabled="cancelEditable" />
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <select v-model="user.branch_id"  :disabled="cancelEditable">
                  <option value="">Select Branch</option>
                  <option v-for="(branch,i) in branches" :key="'branch'+i" :value="branch.id ">{{branch.name}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label class="chk_label">
                  <span>User</span>
                  <input type="radio" class="chk_prefered" :disabled="cancelEditable" :checked="user.is_admin === 0"/>
                </label>
              </div>
              <div class="label_and_element_wrapper">
                <label class="chk_label">
                  <span>Hub Admin</span>
                  <input type="radio" class="chk_prefered" :disabled="cancelEditable" :checked="user.is_admin === 1 && user.is_super_admin == 0"/>
                </label>
              </div>
              <div class="label_and_element_wrapper">
                <label class="chk_label">
                  <span>Customer Service</span>
                  <input type="radio" class="chk_prefered" :disabled="cancelEditable" :checked="user.is_admin === 1 && user.is_super_admin === 1 && user.is_service_user == 1"/>
                </label>
              </div>
              <div class="label_and_element_wrapper">
                <label class="chk_label">
                  <span>Super Admin</span>
                  <input type="radio" class="chk_prefered" :disabled="cancelEditable" :checked="user.is_admin === 1 && user.is_super_admin === 1 && user.is_service_user == 0"/>
                </label>
              </div>
            </td>
            <td>
              <a class="edit_icon" title="EDIT" @click="user_id = user.id,is_editable=true"></a>
              <a class="delete_icon" title="DELETE" @click="deleteUser(user.id)"></a>
              <a class="view_icon" title="VIEW" @click="user_id = user.id, is_editable=false"></a>
            </td>
          </tr>
          <tr v-if="!users.length">
            <td colspan="5"><center><h1>No User</h1></center></td>
          </tr>
        </tbody>
      </table>
      <table>
        <tr class="navigate-button">
            <template v-if="filter.page <= 1">
            <td class="button" :class="filter.page <= 1 ? 'disabled' :''">
              <span class="button-paginate">&laquo;</span>
            </td>
            </template>
            <template v-else>
            <td class="button" @click="fetchUsers('prev')" :class="filter.page == 1 ? 'disabled' :''">
              <span class="button-paginate">&laquo;</span>
            </td>
            </template>
            <td colspan="3" class="page-indicate">
              <input type="text" @keydown="checkDigit" v-model="filter.page" class="filter-page" @change="fetchUsers('straight')">
              <span class="total-page">of <h3><b>{{ filter.total_page }}</b></h3></span>
            </td>
            <template v-if="filter.page >= filter.total_page">
            <td class="button" :class="filter.page >= filter.total_page ? 'disabled' :''">
              <span class="button-paginate" style="float:right">&raquo;</span>
            </td>
            </template>
            <template v-else>
            <td class="button" @click="fetchUsers('next')"  :class="filter.page == filter.total_page ? 'disabled' :''">
              <span class="button-paginate" style="float:right">&raquo;</span>
            </td>
            </template>
          </tr>
          
      </table>
    </div>

    <div class="black-overlay" v-if="user_id">
      <div class="box-modal" style="background:gold;">
        <div class="body">
          <UserManagementAddUserPopup v-if="user_id" :user_id="user_id" @closeModal="fetchUsers" :is_editable="is_editable" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserManagementAddUserPopup from "./UserManagement_AddUserPopup.vue";
export default {
  name: "UserManagement",
  components: {
    UserManagementAddUserPopup
  },
  data() {
    return {
      user_id:"",
      is_editable:true,
      cancelEditable: true,
      branches:[],
      users:[],
      searchValue:'',
      filter:{
        text:'',
        role:'',
        page:1,
        next_page:1,
        total_page:1,
      },
    };
  },
  computed:{
    // usersData() {
    //   return this.users.filter((user) => {
    //     return this.filterText.toLowerCase().split(' ').every(v => user.name.toLowerCase().includes(v)) ||
    //       this.filterText.toLowerCase().split(' ').every(v => user.email.toLowerCase().includes(v))
    //   })
    // }
  },
  created(){
    this.axios.get("/api/branch/view")
      .then(response => {
        this.branches = response.data.branches;
      })
      .catch(error => {
        console.log(error);
      });
    this.fetchUsers();
  },
  methods: {
    checkDigit(event) {
      if (event.key.length === 1 && isNaN(Number(event.key))) {
        event.preventDefault();
      }
    },
    scrollToTop() {
      const element = document.getElementById("user-table-management");
      element.scrollIntoView({ behavior: 'smooth'});
    },
    fetchUsers(type = 'reset') {
      this.user_id = '';
      let page = 1
      if ('reset' == type) {
        page = 1
      } else if('next' == type) {
        page = parseInt(this.filter.page) + 1
      } else if('prev' == type) {
        page = parseInt(this.filter.page) - 1
      } else if ('straight' == type) {
        page = parseInt(this.filter.page)
      }
      let filter = {
        text:this.filter.text,
        role:this.filter.role,
        page:page,
      }
      console.log(JSON.stringify(filter))
      this.axios.post("/api/user/all", filter)
      .then(response => {
          this.users = response.data.data.users;
          this.filter.page = page
          this.filter.total_page = response.data.data.total_page;
          this.scrollToTop()
      })
      .catch(error =>{
          console.log(error);
      });
    },
    deleteUser(id) {
      if (confirm('Are you sure you want to delete this User ?')) {
          this.axios.delete("/api/user/"+ id)
          .then(response => {
              this.toast.success(response.data.msg);
              this.fetchUsers();
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  }
};
</script>
<style lang="scss"  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0;
}
.jobs_table.sp2 table tr td {
  padding: 5px 10px;
}
.chk_label {
  display: inline-block;
  margin-right: 20px;
  background: #fff;
  height: 40px;
  border: 1px solid #ccc;
}
.chk_label span {
  display: inline-block;
  position: relative;
  padding: 0 0 0 20px;
  top: 5px;
}
.chk_label input {
  display: inline-block;
  position: relative;
  top: 10px;
}
.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #06A5ED;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 0 5px;
}
.width_33 {
  width: 320px;
}
.width_22 {
  width: 160px;
}
.edit_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../assets/ico_edit.png) no-repeat;
  background-size: cover;
  margin: 5px 10px;
}
.delete_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../assets/ico_delete.png) no-repeat;
  background-size: cover;
  margin: 5px 10px;
}
.view_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../assets/ico_view.png) no-repeat;
  background-size: cover;
  margin: 5px 10px;
}
.jobs_table.sp2 {
  .table {
    border-radius: 5px;
    width: 100%;
    thead {
      background: #ececec;
      th {
        padding:5px;
      }
    }
    tbody {
      background: #ececec59;
    }
  }
}

.search-text {
  background-color: #004a7c;
  margin: 0px;
  width: 100%;
  padding: 15px;
  display: flex;
  .filter-text {
    margin-left: auto;
    padding: 5px;
    font-size: 1rem;
  }
  .role {
    padding: 5px;
    font-size: 1rem;
  }
}
.navigate-button {
  
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  .page-indicate {
    display: flex;
    .filter-page {
      width: 100%;
      text-align: center;
      font-size: 1.2rem;
      background: #eeeeee;
      border: unset;
    }
  }
  .button:hover{
    background-color: #004a7c;
    color:white;
    cursor: pointer;
  }
  .disabled:hover{
    background-color: #6e6e6e;
    color:white;
    cursor: pointer;
  }
  .button {
    .button-paginate {
      font-size:1.5rem;
    }
  }
}
</style>